import { Icon } from "@makerdao/dai-ui-icons";
import React from "react";
import { Flex, Text, Image } from "theme-ui";
import { staticFilesRuntimeUrl } from "helpers/staticPaths";

import { AppLink } from "./Links";
import { Notice } from "./Notice";

interface HomePageBannerProps {
  heading: string;
  link: string;
  image?: string;
}
const handleClose = () => null;
export function HomePageBanner({ image, heading, link }: HomePageBannerProps) {
  return (
    <Notice
      close={() => {
        handleClose();
      }}
      sx={{
        marginBottom: 0,
        overflow: "hidden",
        borderRadius: "50px",
        maxWidth: ["400px", "520px"],
        p: "3px 8px 3px 4px",
        backgroundColor: "#ffffff10",
        "&:hover": {
          opacity: "80%",
          cursor: "pointer",
        },
      }}
      withClose={false}
    >
      <AppLink target="_blank" href={link}>
        <Flex
          sx={{
            justifySelf: "center",
            alignItems: "center",
            textAlign: "left",
            flexDirection: ["row"],
            justifyContent: ["start", "space-between"],
            minHeight: "44px",
            "&:hover svg": {
              transform: "translateX(10px)",
            },
          }}
        >
          {image ? (
            <Image
              src={staticFilesRuntimeUrl(image)}
              sx={{
                width: "42px",
                height: "42px",
                objectFit: "contain",
                mr: "8px",
              }}
            />
          ) : (
            <Flex
              sx={{
                // background: "#D3F3F5",
                borderRadius: "50px",
                flexDirection: "row",
                justifySelf: "center",
                alignItems: "center",
                textAlign: "left",
                minHeight: "42px",
                minWidth: "44px",
                px: "8px",
                mr: "8px",
              }}
            >
              <Icon
                name="steth_circle_color"
                size="30px"
                sx={{
                  transform: "none !important",
                }}
              />
            </Flex>
          )}
          <Flex
            sx={{
              flex: 1,
              flexDirection: ["column", "row", "row", "row"],
              mr: "7px",
              // justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Text
              variant="text.paragraph3"
              sx={{ flex: 1, zIndex: 1, fontWeight: "semiBold", wordBreak: "normal" }}
            >
              {`${heading.split(".")[0]}.`}
            </Text>
            <Flex
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text
                variant="text.paragraph3"
                sx={{
                  zIndex: 1,
                  fontWeight: "semiBold",
                  wordBreak: "normal",
                  whiteSpace: [null, "pre"],
                }}
              >
                {` ${heading.split(".")[1]}`}
              </Text>
              <Icon
                key="arrow"
                name="arrow_right"
                size="20px"
                sx={{
                  position: "relative",
                  ml: "4px",
                  transition: "0.2s",
                  color: "black",
                  pr: 2,
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </AppLink>
    </Notice>
  );
}
